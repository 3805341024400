import food1 from "../assets/images/cakes/tiered/pexels-jeremy-wong-1038711.jpg";
import food2 from "../assets/images/cakes/sliced/pexels-christina-polupanova-10281284.jpg";
import food3 from "../assets/images/cookies/brownies/pexels-vjapratama-13072135.jpg";
import food4 from "../assets/images/cookies/pexels-jill-wellington-3553703.jpg";
import food5 from "../assets/images/cookies/pexels-darlene-alderson-7016318.jpg";
import food6 from "../assets/images/breads/pexels-karolina-grabowska-4197905.jpg";
import food7 from "../assets/images/breads/pexels-pixabay-2135.jpg";
import food8 from "../assets/images/pizzas/pexels-rene-strgar-10836977.jpg";
import food9 from "../assets/images/pizzas/pexels-roman-odintsov-5903450.jpg";

export default [food1, food2, food3, food4, food5, food6, food7, food8, food9];
