import React from "react";
import { Hero } from "../components/Hero.comp";
import { food } from "../components/Data";
import Feature from "../components/Feature.comp";
import Display from "../components/Display.comp";
import About from "../components/About.comp";
import Slider from "../components/Slider.comp";

export const Home = () => {
  return (
    <div>
      <Hero images={food} />
      <Display />
      <Feature pictures={food} />
      <About />
    </div>
  );
};
