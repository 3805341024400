import React from "react";
import NewProduct from "../components/NewProduct.comp";
import Slider from "../components/Slider.comp";

const Cakes = () => {
  return (
    <div>
      <NewProduct />
      <Slider title="Tiered Cakes" />
      <Slider title="Sold Per Slice Cakes" />
      <Slider title="Gluten Free Cakes" />
    </div>
  );
};

export default Cakes;
