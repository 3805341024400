import React from "react";
import newimage from "../assets/images/cake_icon.jpg";
import { useState } from "react";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const NewProduct = () => {
  let [count, setCount] = useState(0);
  let LeftAmount = 5;
  const handlePosCounter = () => {
    setCount(count + 1);
  };
  const handleNegCounter = () => {
    if (count === 0) {
      setCount(0);
    } else {
      setCount(count - 1);
    }
  };
  return (
    <section className="layout cakes">
      <section className="cakes_product">
        <div className="new feature_2">
          <img
            className="new_image"
            src={newimage}
            alt="Check out our new recipes"
          />
          <div className=" new_left feature_2_left">
            <h4 className="title">NEW PRODUCT</h4>
            <p>Just {LeftAmount} left</p>
            <h1>VANILLA CAKE WITH CHOCOLATE BUTTERCREAM FILLING</h1>
            <p>
              It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
            <div className="product_price"></div>
            <div className="buttons">
              <button
                className="counter"
                type="button"
                onClick={handlePosCounter}
              >
                +
              </button>
              <div className="result">{count}</div>
              <button
                className="counter"
                type="button"
                onClick={handleNegCounter}
              >
                -
              </button>
              <button className="cart counter hero_button" type="button">
                Add to Cart
              </button>
            </div>
          </div>
        </div>
        new product portion which lasts for 2 weeks and then the section
        disappears carousel of different kinds of cake
      </section>
      <section className="feature">
        sends to details page to show ingredients{" "}
      </section>
    </section>
  );
};

export default NewProduct;
