import style from "../scss/style";

export const food = [
  {
    image: "/images/cakes/sliced/pexels-anna-belousova-10360611.jpg",
    title: "VANILLA CHOCOLATE SWIRL CAKE",
    bgColor: "white",
    color: "black",
  },
  {
    image: "/images/cakes/cupcakes/pexels-jess-bailey-designs-853004.jpg",
    title: "STRAWBERRY CHIFFON CAKE WITH FILLING ",
    bgColor: "teal",
    color: "black",
  },
  {
    image: "/images/cakes/cupcakes/food-2.jpg",
    title: "CUPCAKES WITH CREAMCHEESE ICING ",
    bgColor: "pink",
    color: "black",
  },
];
