import { motion } from "framer-motion";
import images from "./Images";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

export const Slider = ({ title }) => {
  const [width, setWidth] = useState(0);
  const carousel = useRef();
  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  }, []);

  return (
    <section className="layout">
      <h2 className="heading">{title}</h2>
      <motion.div
        ref={carousel}
        className="carousel"
        whileTap={{ cursor: "grabbing" }}
      >
        <motion.div
          drag="x"
          dragConstraints={{ right: 0, left: -width }}
          className="carousel-inner"
        >
          {images.map((image) => {
            return (
              <motion.div className="carousel-item overlay" key={image}>
                <div className="overlay">
                  <button className="button">
                    <Link className="button" to="/details">
                      SHOP &rsaquo;
                    </Link>
                  </button>
                </div>
                <img src={image} alt="" />
              </motion.div>
            );
          })}
        </motion.div>
      </motion.div>
    </section>
  );
};
export default Slider;
