import React from "react";
import { Link, Route, Router } from "react-router-dom";
import { Home } from "../pages/Home.page";
import Cakes from "../pages/Cakes.page";

export const Header = () => {
  return (
    <header className="header layout">
      <nav className="nav">
        <div className="nav_logo">
          sweeties
          {/* <img
            src={logo}
            width="50px"
            alt="Microchip logo.Click to send you back to home"
          /> */}
        </div>
        <div className="nav_links">
          <Link to="/">HOME</Link>
          <Link to="/cakes">CAKES</Link>
          <Link to="/cookies">COOKIES</Link>
          <Link to="/bread">BREADS</Link>
        </div>
        <div className="nav-icon"></div>
      </nav>
    </header>
  );
};
