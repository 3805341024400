import React from "react";
import "./App.css";
import { DefaultLayout } from "./layout/DefaultLayout";
import { Home } from "./pages/Home.page";
import "./scss/style";
import Details from "./pages/Details.page";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cakes from "./pages/Cakes.page";

function App() {
  return (
    <BrowserRouter>
      <DefaultLayout>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/details" element={<Details />}></Route>
          <Route path="/cakes" element={<Cakes />}></Route>
        </Routes>
      </DefaultLayout>
    </BrowserRouter>
  );
}

export default App;
