import React from "react";
import image from "../assets/images/cakes/prod of cakes/pexels-felicity-tai-7966401.jpg";

const About = () => {
  return (
    <section className="about layout">
      <div className="about_right feature_2_right">
        <h2>
          BRINGING YOU THE <strong>BEST</strong> BAKED EXPERIENCE
        </h2>
        <p>
          {" "}
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </p>
      </div>
      <div className="about_left feature_2_left">
        <img src={image} alt="someone eating cake" />
      </div>
    </section>
  );
};

export default About;
