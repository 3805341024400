import React from "react";
import logo from "../assets/images/microchip-solid.svg";
import facebookLogo from "../assets/images/facebook-icon.svg";
import instagramLogo from "../assets/images/instagram-icon.svg";

export const Footer = () => {
  return (
    <footer className="footer layout">
      <div className="footer_nav">
        <div className="nav_logo">
          sweeties
          {/* <img
              src={logo}
              width="50px"
              alt="Microchip logo.Click to send you back to home"
            /> */}
        </div>
        <div className="footer_nav_links nav_links">
          <a href="#home">HOME</a>
          <a href="cakes">CAKES</a>
          <a href="cookies">COOKIES</a>
          <a href="bread">BREADS</a>
        </div>
      </div>
      <div className="footer_text">
        <div className="footer_text_desciption">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </div>
        <div className="footer_text_socials">
          <ul>
            <li>
              <a href="#about">
                <img
                  src={facebookLogo}
                  alt="Facebook logo. Click to go to facebook page"
                />
              </a>
            </li>
            <li>
              <a href="#about">
                <img
                  src={instagramLogo}
                  alt="Instagram logo. Click to go to Instagram page"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer_copyright">
        {" "}
        &copy; Adjoa Anaman. All right reserved{" "}
      </div>
    </footer>
  );
};
