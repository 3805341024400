import React from "react";
import image from "../assets/images/cake_icon.jpg";
import image2 from "../assets/images/cookies.png";
import image3 from "../assets/images/pizza-slice-vector-icon.jpg";
import { Link } from "react-router-dom";

const Display = () => {
  return (
    <section className="display layout">
      <div className="display_wrap">
        <ul>
          <li>
            <Link to="/cakes">
              <button type="button">
                <img src={image} alt="Item sold with description and shop" />
                <h3>CAKES</h3>
                <p>SHOP &rsaquo;</p>
              </button>
            </Link>
          </li>
          <li>
            <button type="button">
              <img src={image2} alt="Item sold with description and shop" />
              <h3>COOKIES</h3>
              <p>SHOP &rsaquo;</p>
            </button>
          </li>
          <li>
            <button type="button">
              <img src={image3} alt="Item sold with description and shop" />
              <h3>PIZZAS</h3>
              <p>SHOP &rsaquo;</p>
            </button>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Display;
