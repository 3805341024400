import React from "react";
import image1 from "../assets/images/cakes/tiered/pexels-rodrigo-souza-2531546.jpg";
import image2 from "../assets/images/cookies/pexels-george-dolgikh-2072173.jpg";
import image3 from "../assets/images/breads/pexels-markus-spiske-1871024.jpg";

const Feature = () => {
  return (
    <section className=" feature layout">
      <div className="feature_1">
        <div className="feature_1_right">
          <img src={image1} alt="Items sold in shop and description" />
        </div>
        <div className="feature_1_left">
          <h2>VANILLA SWIRL CAKE</h2>
          <p>
            {" "}
            "Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s.",
          </p>
          <button className=" hero_button button">SEE PRODUCT</button>
        </div>
      </div>
      <div className="feature_2">
        <div className="feature_2_right">
          <img src={image2} alt="Items sold in shop and description" />
        </div>
        <div className="feature_2_left">
          <h2>VANILLA SWIRL CAKE</h2>

          <button className=" hero_button button">SEE PRODUCT</button>
        </div>
      </div>
      <div className="feature_3">
        <div className="feature_3_right">
          <img src={image3} alt="Items sold in shop and description" />
        </div>
        <div className="feature_3_left">
          <h2>VANILLA SWIRL CAKE</h2>

          <button className=" hero_button button">SEE PRODUCT</button>
        </div>
      </div>
    </section>
  );
};

export default Feature;
