import React, { useEffect, useState } from "react";

export const Hero = ({ images }) => {
  const [current, setCurrent] = useState(0);
  const [autoPlay, setAutoplay] = useState(true);
  let timeOut = null;
  useEffect(() => {
    timeOut =
      autoPlay &&
      setTimeout(() => {
        slideRight();
      }, 2500);
  });

  const slideLeft = () => {
    setCurrent(current === images.length - 1 ? 0 : current + 1);
  };
  const slideRight = () => {
    setCurrent(current === 0 ? images.length - 1 : current - 1);
  };
  return (
    <section className="container">
      <section
        className="hero_wrapper"
        onMouseEnter={() => {
          setAutoplay(false);
          clearTimeout(timeOut);
        }}
        onMouseLeave={() => {
          setAutoplay(true);
        }}
      >
        {images.map((image, index) => {
          return (
            <div
              key={index}
              className={index === current ? "hero hero-active" : "hero"}
              style={{
                backgroundColor: `${image.bgColor}`,
                color: `${image.color}`,
              }}
            >
              <div className="hero_right">
                <div
                  className="hero_right_image"
                  style={{
                    backgroundImage: `url(${image.image})`,
                  }}
                ></div>
              </div>
              <div className="hero_left">
                <div>
                  <h4 className="title">NEW PRODUCT</h4>
                  <h1>{image.title}</h1>
                  <p>
                    Experience natural, lifelike, melt-in-mouth and tasty
                    quality for the passionate cake entusiast.
                  </p>
                  <button className=" hero_button button">SEE PRODUCT</button>
                </div>
              </div>
            </div>
          );
        })}
        <div className="hero-arrow-left" onClick={slideLeft}>
          &lsaquo;
        </div>
        <div className="hero-arrow-right" onClick={slideRight}>
          &rsaquo;
        </div>
        <div className="hero_paginate">
          {images.map((_, index) => {
            return (
              <div
                key={index}
                className={
                  index === current
                    ? "pagination_dot pagination_dot-active"
                    : "pagination_dot"
                }
                onClick={() => setCurrent(index)}
              ></div>
            );
          })}
        </div>
      </section>
    </section>
  );
};
